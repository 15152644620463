import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        cspNonce: vuetifyValue,
        themes: {
            light: {
                primary: '#A03058',
                lightpink: '#FEFBFC',
                secondary: '#B6B4B5',
                accent: '#BCAAB4',
                'accent-blue': '#5973B1',
                info: '#94A5E2',
                error: '#F44336',
                success: '#8BC34A',        
              }
        },
    },
});
